<template>
    <div>
        <FilterModal
            @reset="resetFilter"
            @filter="getFilterData"
            :isLoading="isLoading"
            ref="filterModal"
        >
            <template v-slot:filters>
                <TitleTypeAccountFilter
                    ref="titleTypeAccountFilter"
                    v-model:titleType="filterPayload.titleType"
                    v-model:manCodes="filterPayload.manCodes"
                    :hasSelectedALL="true"
                />
                <Dropdown
                    type="text"
                    :required="true"
                    :title="filterTitles.birthdayType"
                    :searchable="false"
                    :options="filterOptions.birthdayType"
                    v-model="filterPayload.birthdayType"
                />
                <template v-if="filterPayload.birthdayType">
                    <component
                        :is="currentOption"
                        :required="filterPayload.birthdayType !== ''"
                        max="2020-12-31"
                        min="2020-01-01"
                        :isOnlyDate="true"
                        :title="
                            filterPayload.birthdayType === 'insBirthday'
                                ? '保險年齡增加日期'
                                : '生日日期'
                        "
                        v-model:startDate="filterPayload.dateStart"
                        v-model:endDate="filterPayload.dateEnd"
                    />
                </template>
                <Textbox
                    name="customerName"
                    title="客戶姓名"
                    type="text"
                    v-model="filterPayload.customerName"
                    placeholder="請輸入客戶姓名"
                />
            </template>
        </FilterModal>
    </div>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue'
import DateRangeTextbox from '@/components/DateRangeTextbox.vue'
import Textbox from '../../components/Textbox.vue'
import FilterModal from '@/containers/FilterModal.vue'
import TitleTypeAccountFilter from '@/containers/TitleTypeAccountFilter.vue'
import { customerFilterTitles } from '@/assets/javascripts/tia/setting'
import { getCustomerSearchCodesAPI } from '@/assets/javascripts/api'
import filterDefault from '@/assets/json/filterDefault.json'

export default {
    name: 'CustomerFilter',
    emits: [
        'show-data-table',
        'update:filterPayloadProp',
        'update:filterLogProp'
    ],
    components: {
        Dropdown,
        FilterModal,
        DateRangeTextbox,
        TitleTypeAccountFilter,
        Textbox
    },
    props: {
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    manCodes: ''
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        resetFilter: function () {
            this.filterPayload = {
                birthdayType: this.filterOptions.birthdayType[0].value ?? '',
                manCodes: this.filterPayload?.manCodes,
                ...filterDefault.customer
            }
            this.$refs.titleTypeAccountFilter.resetFilter()
            this.filterPayload = this.$getStorePayload(this.filterPayload)
            this.$emit('update:filterPayloadProp', this.filterPayload)
        },
        getFilterData: async function () {
            /*
             ** 若送api的payload有異動 要重設進階篩選
             ** 若api的payload無異動 只需做進階篩選
             */
            if (
                !this.$isSameValue(
                    this.perviousFilterPayload,
                    this.filterPayload
                ) ||
                this.$hasCrossFilter()
            ) {
                this.perviousFilterPayload = this.$cloneDeepValue(
                    this.filterPayload
                )
                this.$emit('show-data-table')
            }
            this.filterLog = this.$getFilterLog(this.filterPayload)
            this.hasCrossFilter = false
        },
        getDropdownOptions: async function () {
            this.$showLoading()
            try {
                let res = await getCustomerSearchCodesAPI()
                this.dropdownOptions = res.data
            } catch {
                this.$showErrorMessage(
                    '載入時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                this.$hideLoading()
            }
        }
    },
    computed: {
        filterOptions: function () {
            return {
                birthdayType: this.allOption.concat(
                    this.dropdownOptions.birthdayType?.map((data) => {
                        return {
                            label: data.value,
                            value: data.key
                        }
                    })
                )
            }
        }
    },
    watch: {
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        },
        'filterPayload.customerName': {
            handler() {
                if (!this.hasCrossFilter) this.filterPayload.customerCode = null
            }
        },
        'filterPayload.birthdayType': {
            handler(option) {
                if (option) this.currentOption = 'DateRangeTextbox'
            }
        }
    },
    data() {
        return {
            dropdownOptions: {
                birthdayType: []
            },
            allOption: [{ label: '全部', value: '' }],
            perviousFilterPayload: {},
            filterPayload: this.filterPayloadProp,
            mType: '',
            filterTitles: {},
            filterLog: this.filterLogProp,
            hasCrossFilter: this.$hasCrossFilter(),
            isMounted: true,
            currentOption: null
        }
    },
    async mounted() {
        this.filterTitles = { ...customerFilterTitles }
        await this.getDropdownOptions()
        this.resetFilter()
        if (this.$route.query.customerCode) {
            this.filterPayload.customerCode = parseInt(
                this.$route.query.customerCode
            )
            this.filterPayload.customerName = this.$route.query.customerName
            window.history.replaceState({}, '', '/tia/customer')
        }
        if (this.hasCrossFilter || this.$route.query.customerCode)
            await this.getFilterData()
        this.isMounted = false
    },
    async activated() {
        if (this.isMounted) return
        if (this.$hasCrossFilter()) {
            this.resetFilter()
            await this.getFilterData()
        }
    }
}
</script>

<style lang="scss" scoped>
.insStatus-filter {
    :deep(.checkbox-container) {
        padding-left: 1px;
    }
}
</style>
